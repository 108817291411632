
import { Options, Vue } from "vue-class-component";
import Layout from "@/components/Layout.vue";
import Title from "@/components/Title.vue";

@Options({
  components: {
    Layout,
    Title,
  },
})
export default class Error extends Vue {
  get type() {
    return this.$route.query.type || "global";
  }
}
